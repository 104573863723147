import React from 'react'
import Helmet from 'react-helmet'

import Layout from "../../components/layout"
import BgImage from '../../components/bg-image'

const Lighting = () => (
  <Layout>
    <Helmet title="Pool" />
    <BgImage image="/assets/pool-resurfacing.jpg" className="top">
      <h1>Heating and Lighting</h1>
    </BgImage>
    <section className="content">
      <p>
        Want to get more from your swimming pool? For a comfortable and
        enjoyable swimming pool, heating and lighting is often overlooked, but
        very important. With the right heating and lighting solution, you will
        be able to enjoy your swimming pool in any season.
      </p>
      <p>
        Heating is a great way to make sure the water is just the right
        temperature when you jump in. We have a range of different options from
        Electric Heat Pumps to Gas Heaters to Solar Powered solutions. Let us
        help you to find the right heating solution for your newly renovated
        pool, such as:
      </p>
      <ul>
        <li>Solar Heating</li>
        <li>Heat Pumps</li>
        <li>Gas Heaters</li>
        <li>Surface Mounted</li>
        <li>Flush Mounted</li>
      </ul>
      <p>
        The right swimming pool lighting will create an atmosphere that can
        really showcase the beauty of your pool at night. Pool lights can also
        provide safety for your pool and pool deck, where the path may be
        slippery or hard to see.
      </p>
      <p>Speak to us about the available options.</p>
    </section>
  </Layout>
)

export default Lighting
